<template>
  <div class="lock-log">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <ul class="tab-ul">
      <li :class="{ 'ative': currentTab == 'cabinetInfo' }" @click="toggleTab('cabinetInfo')">柜锁信息</li>
      <li :class="{ 'ative': currentTab == 'doorOpeningLog' }" @click="toggleTab('doorOpeningLog')">开门日志</li>
      <li :class="{ 'ative': currentTab == 'openLockInfoList' }" @click="toggleTab('openLockInfoList')">开锁人信息</li>
      <li v-show="row.lockTypeName == 'WF-G1K'" :class="{ 'ative': currentTab == 'ICCardInformationWave' }" @click="toggleTab('ICCardInformationWave')">IC卡信息</li>
    </ul>
    <keep-alive>
      <doorOpeningLog :is="currentTab"></doorOpeningLog>
    </keep-alive>

  </div>
</template>
<script>
import cabinetInfo from './cabinetInfo';
import doorOpeningLog from './doorOpeningLog';
import openLockInfoList from './openLockInfoList';
import ICCardInformationWave from './ICCardInformationWave';
export default {
  components: {
    cabinetInfo,
    doorOpeningLog,
    openLockInfoList,
    ICCardInformationWave,
  },
  data() {
    let _this = this;
    return {
      currentTab: 'cabinetInfo',
      title: "绑锁详情",
      row: "",
    };
  },
  activated() {
    this.row = this.$route.params;
    this.currentTab = 'cabinetInfo';
  },
  mounted() {
    this.currentTab = 'cabinetInfo';
  },
  methods: {
    toggleTab: function (tab) {
      this.currentTab = tab; // tab 为当前触发标签页的组件名
    },
  }
};
</script>
<style lang="scss">
.lock-log {
  .danger {
    color: #f56c6c;
  }

  .titleSpans {
    padding-bottom: 20px;
  }

  .titleSpan {
    color: #606266;
  }

  .info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;

    .txt {
      margin-right: 20px;
    }
  }

  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      margin-right: 20px;
    }
  }

  // tab样式
  .tab-ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    height: 42px;
    box-sizing: border-box;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 2px solid #e4e7ed;

    li {
      cursor: pointer;
      padding-left: 18px;
      padding-right: 18px;
      height: 42px;
      line-height: 42px;
      float: left;
      text-align: center;
      margin-right: 13px;
      font-size: 15px;
      background-color: #ffffff;
      color: #303133;
      box-sizing: border-box;
      border-bottom: 2px solid #e4e7ed;

      &.ative {
        // color: #ffffff;
        // background: #5e74f1;
        color: #30BAC1;
        border-bottom: 2px solid #30BAC1;
      }
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .landlord-manage-box .el-tab-pane {
    height: 650px;
  }
}
</style>
