<template>
  <div class="table-box">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          columns: [
            {
              label: "开锁人姓名",
              key: "realName"
            },
            {
              label: "电话号码",
              key: "phone"
            },
            {
              label: "角色",
              key: "roleType"
            },
            {
              label: "最近一次开锁时间",
              key: "recentUnLockTime"
            },
             {
              label: "添加时间",
              key: "createTime"
            },
          ]
        },
      };
    },
    created() {
      console.log("openLockInfoList created!!");

    },
    activated() {
      console.log("openLockInfoList activated!!");
    },
    mounted() {

    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          deviceId: this.$route.params.id,
          // deviceId:'1556937634111614977',//测试数据
        };
        this.post("/homelock-service/v1/home-lock/query/grant-user-list", dto, {
          isUseResponse: true
        }).then(res => {
          for (let i = 0; i < res.data.data.data.length; i++) {
            let item = res.data.data.data[i];
            item.recentUnLockTime = item.recentUnLockTime ? item.recentUnLockTime : '--';
            item.roleType = ['管理员','普通用户'][item.type - 1];
            // if(item.grantUserId) {
            //   item.roleType = '普通用户';
            // }else {
            //   item.roleType = '管理员';
            // }
            
          }
          opt.cb(res.data.data);
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .table-box {
    height: 620px;
  }


.content {
  padding-left: 39px;
}
  .content-row {
    padding-bottom: 24px;
  }

  .failureDetail {
    display: flex;
  }

  .dialog-footer {
    text-align: center;
  }
</style>