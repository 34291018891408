<template>
  <div class="cabinetIfno">
    <div class="cabinetIfno-lt">
      <div class="content-box" v-for="item in dataListLeft" :key="item.key">
        <div class="title">{{item.label}}</div><span class="content">{{form[item.key]}}{{item.value}}</span>
      </div>
    </div>
    <div class="cabinetIfno-rt">
      <div class="content-box" v-for="item in dataListRight" :key="item.key">
        <div class="title">{{item.label}}</div><span class="content">{{form[item.key]}}{{item.value}}</span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        dataListLeft: [{
            label: "柜锁型号：",
            key: "lockTypeName",
            isCol50: true,
          },
          {
            label: "MAC地址：",
            key: "mac",
            isCol50: true,
          },
          {
            label: "柜锁名称/编号：",
            key: "lockInfo",
            isCol50: true,
          },
          {
            label: "设备端软件版本：",
            key: "firmwareVersion",
            isCol50: true,
          },
          {
            label: "管理员（用户）:",
            key: "realName",
            isCol50: true,
          },
          {
            label: "管理员手机号码:",
            key: "phone",
            isCol50: true,
          },
          {
            label: "绑定时间：",
            key: "createTime",
            isCol50: true,
          },
        ],
        dataListRight: [{
            label: "最近一次开锁时间：",
            key: "recentUnLockTime",
            isCol50: true,
          },
          {
            label: "绑锁状态:",
            key: "bindStateStr",
            isCol50: true,
          },
          {
            label: "柜锁分组：",
            key: "groupName",
            isCol50: true,
          },
          {
            label: "解绑人：",
            key: "unBoundUserName",
            isCol50: true,
          },
          {
            label: "解绑人账号：",
            key: "unBoundUserAccount",
            isCol50: true,
          },
          {
            label: "解绑时间：",
            key: "unBoundDate",
            isCol50: true,
          },
        ],
        form: {}
      };
    },
    created() {
      console.log("cabinetIfno created!!");

    },
    activated() {
      console.log("cabinetIfno activated!!");

      let dto = {
        deviceId: this.$route.params.id,
      };
      this.post("/homelock-service/v1/home-lock/query/lock-detail", dto, {
        // isUseResponse: true
      }).then(data => {
        data.bindStateStr = ['已解绑', '已绑定'][data.bindState];
        if(data.lockCode && data.lockName) {
          data.lockInfo = data.lockName + '/' + data.lockCode;
        }
        data.phone = data.phone ? data.phone : '--';
        data.recentUnLockTime = data.recentUnLockTime ? data.recentUnLockTime : '--';
        this.$set(this, 'form', data);
        if (data.bindState == 0) {
          data.unBoundUserName = data.unBoundUserName ? data.unBoundUserName : '--'
          data.unBoundUserAccount = data.unBoundUserAccount ? data.unBoundUserAccount : '--'
          data.unBoundDate = data.unBoundDate ? data.unBoundDate : '--'
        }
        if (data.bindState == 0) {
          this.dataListRight = [{
            label: "最近一次开锁时间：",
            key: "recentUnLockTime",
            isCol50: true,
          },
          {
            label: "绑锁状态:",
            key: "bindStateStr",
            isCol50: true,
          },
          {
            label: "柜锁分组：",
            key: "groupName",
            isCol50: true,
          },
          {
            label: "解绑人：",
            key: "unBoundUserName",
            isCol50: true,
          },
          {
            label: "解绑人账号：",
            key: "unBoundUserAccount",
            isCol50: true,
          },
          {
            label: "解绑时间：",
            key: "unBoundDate",
            isCol50: true,
          }]
        } else {
          this.dataListRight = [{
            label: "最近一次开锁时间：",
            key: "recentUnLockTime",
            isCol50: true,
          },
          {
            label: "绑锁状态:",
            key: "bindStateStr",
            isCol50: true,
          },
          {
            label: "柜锁分组：",
            key: "groupName",
            isCol50: true,
          }]
        }
      })
    },
    mounted() {

    },
    methods: {

    }
  };
</script>
<style lang="scss" scoped>
  .table-box {
    height: 620px;
  }

  .cabinetIfno {
    margin: auto;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;

    .cabinetIfno-lt {
      width: 50%;
    }

    .cabinetIfno-rt {
      width: 50%;
    }

    .content-box {
      margin: 20px 0;
      font-size: 15px;

      .title {
        display: inline-block;
        padding-right: 40px;
        text-align: right;
        width: 200px;
        color: #303133;
        // font-weight: 700;
      }


      .content {
        color: #666;
      }
    }

    .editBox {
      display: inline-block;
      margin: auto;

      .editTitle {
        display: inline-block;
        padding-right: 10px;
        text-align: right;
        width: 101px;
        color: #606266
      }

      .editContent {
        margin: 10px;
      }

      .el-textarea {
        width: 53%;
      }

      .el-input {
        width: 53%;
      }
    }

    .cabinetIfno-check-title {
      padding-bottom: 10px;
      font-weight: 700;
    }

    .cabinetIfno-check-text {
      padding-bottom: 10px;
      display: flex;

      span {
        padding-right: 46px;
      }
    }

    .table-box {
      height: 400px;
      overflow-y: auto;
    }
  }
</style>