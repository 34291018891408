<template>
  <div class="table-box">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
import { timeout } from 'q';

export default {
  data() {
    let _this = this;
    return {
      opt: {
        search: [],
        columns: [
          { label: "IC卡名称", key: "name", },
          { label: "IC卡编号", key: "additiveId" },
          { label: "IC卡时效", key: "cardTimeEffectStr" },
          { label: "生效/时效时间", key: "takeEffectTimeStr", width: 380 },
          { label: "IC卡状态", key: "statusStr" },
          { label: "发卡人", key: "senderUsername" },
          {
            label: "发卡人角色",
            key: "roleStr"
          },
          {
            label: "发卡时间",
            key: "createDateStr"
          }
        ]
      },
      roomList: [],
      userInfo: {},
    };
  },
  created() {
    console.log("ICCardInformation created!!");
    this.userInfo = this.cache.getLS("userInfo");
  },
  activated() {
    console.log("ICCardInformation activated!!");
    // this.$refs.appList.onRefresh();
  },
  mounted() {
    console.log("ICCardInformation mounted!!");
  },
  methods: {
    onGet(opt) {
      let lockInfo = this.cache.get("lockInfo")
      // lockInfo.bindRoomState 
      //['已解绑', '已绑定']
      let url = "landlord-service/house/v1/device/additive/history/page"
      let dto = {
        pageNo: opt.skip,
        pageSize: opt.limit,
        deviceId: this.$route.params.id,
        type: 'card',
        state:0
      }
      this.post(url, dto, {
        isUseResponse: true
      }).then(res => {
        if (res.data.data.data) {
          for (let i = 0; i < res.data.data.data.length; i++) {
            let item = res.data.data.data[i];
            item.createDateStr = this.format(item.createTime);
            let _startDate = this.format(item.beginTime);
            let _endDate = this.format(item.endTime);
            if (moment(_startDate).add(49, 'years').isBefore(_endDate)) {
              item.cardTimeEffectStr = '永久';
              item.takeEffectTimeStr = '--'
            }
            else {
              item.cardTimeEffectStr = '限时';
              item.takeEffectTimeStr = this.format(_startDate) + ' ~ ' + this.format(_endDate);
            }
            let timeout = moment().format('x') > moment(item.endTime).format('x') ? 0 : 1
            item.statusStr = ['已失效','有效'][timeout]
            if(item.ownerType == 'tenant') {
              item.roleStr = '房客'
            } else if(item.ownerType == 'landlord') {
              item.roleStr = '房东'
            } else if(item.ownerType == 'engineer') {
              item.roleStr = '工程人员'
            } else if(item.ownerType == 'cohabitant') {
              item.roleStr = '同住人'
            } else if(item.ownerType == 'housekeeper') {
              item.roleStr = '房管员'
            }
          }
        } else {
          res.data.data.data = []
        }
        opt.cb(res.data.data);
      });
    },
  }
};
</script>
<style lang="scss">
.table-box {
  height: 620px;
}
</style>